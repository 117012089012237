import { Component, OnInit, Input } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  @Input() brand_class: string | undefined;
  /**
   * Clients Logo color
   */
  url = "assets/images/logos/technologies/";
  
   clients_logo: client[] = [
    {
      image: this.url + "angularcolor.png"
    },
    {
      image: this.url + "boostraColor.png"
    },
    {
      image: this.url + "html5color.png"
    },
    {
      image: this.url + "laravelColor.png"
    },
    {
      image: this.url + "phpcolor.png"
    },
    {
      image: this.url + "sqlcolor.png"
    },
  ];

  /* Logos white */

  //  clients_logo: client[] = [
  //   {
  //     image: this.url + "angularwhite.png"
  //   },
  //   {
  //     image: this.url + "boostrapolor.png"
  //   },
  //   {
  //     image: this.url + "html5chite.png"
  //   },
  //   {
  //     image: this.url + "laravelwhite1.png"
  //   },
  //   {
  //     image: this.url + "phpwhite.png"
  //   },
  //   {
  //     image: this.url + "sqlcwhite.png"
  //   },
  // ];

  constructor() { }

  ngOnInit(): void {
  }

}
