import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';

interface feature {
  icon: string;
  title: string;
  description: string;
  src: string;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  valores: any;
  /**
   * valores
   */
   featuresdata: any[] = [];
  general: any = {};

  openModal(data: any) {
    this.valores = data;
    // Aquí puedes agregar código para abrir el modal si no estás usando Bootstrap
  }
  
  constructor(private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.loadTranslations();
    this.changeLanguage();
  }
  
  loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.general = data.general;
      // Asigna los valores del JSON al array 
      this.featuresdata = data.featuresdata; 
    });
  }
  
  changeLanguage(){
    this.languageService.onLanguageChanged().subscribe(() => {
      this.loadTranslations();
    });
  }
}
