<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/headers/iframes.png') center center;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h1 class="title text-white title-dark mb-0"> {{iframes.title}} </h1>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item active" aria-current="page">iframes</li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section">
    <div class="container">
        <div class="card-body  text-center">
            <h2 class="card-title">{{ instructions.h1 }}</h2>
            <ul class="list-unstyled text-muted">
                <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ instructions.p_1 }}
                </li><br>
                <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ instructions.p_2 }}
                </li><br>
                <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    {{ instructions.p_3 }}
                </li>
            </ul>

            <form (ngSubmit)="generarIframe()">
                <label for="url">{{ iframes.url }}</label>
                <input type="text" id="url" [(ngModel)]="url" name="url" required>
                <button type="submit">{{ iframes.button }}</button>
            </form>

            <div id="iframes-container" *ngIf="iframeSrc">
                <iframe [src]="iframeSrc" style="display: block; margin: 0 auto; width: 800; height: 500px;" frameborder="0" allowfullscreen allow=" autoplay; ">
        </iframe>
            </div>

            <div id="success-message"></div>
            <div id="error-message"></div>

            <h4 *ngIf="iframeCode">{{ iframes.h4 }}</h4>
            <div id="iframe-code" *ngIf="iframeCode">
                <p>{{ iframeCode }}</p>
                <button (click)="copyText()">{{ iframes.button_copy }}</button>
            </div>
        </div>
        <div class="card shadow rounded border-0">
            <div class="card-body text-center">
                <h5 class="card-title">{{ iframes.h5}}</h5>
                <p class="text-muted">{{ iframes.iframe }}</p>
            </div>
        </div>
    </div>



</section>