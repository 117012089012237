import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LanguageService } from "../../services/language.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-page-cases",
  templateUrl: "./page-cases.component.html",
  styleUrls: ["./page-cases.component.css"],
})

/**
 * Page Cases Component
 */
export class PageCasesComponent implements OnInit {

  Menuoption = 'center';
  url: string = '';
  iframeSrc: SafeResourceUrl | undefined;
  iframeCode: string | undefined;
  
  iframes: any = {}
  instructions: any = {};

  constructor(private modalService: NgbModal,
              private sanitizer: DomSanitizer,
              private languageService: LanguageService,
              private toastr: ToastrService

  ) { }

private scrollPosition: number = 0;
  ngOnInit(): void {
    this.loadTranslations();
    this.languageService.onLanguageChanged().subscribe(() => {
      this.scrollPosition = window.scrollY;
      this.loadTranslations();
      setTimeout(() => window.scrollTo(0, this.scrollPosition), 0); // Restaurar la posición del scroll
    });


    this.toastr.success('Espere por favor...', 'Cargando', {
      timeOut: 400000,
      positionClass: 'toast-top-right',
    });
    this.toastr.success('Hello world!', 'Toastr fun!');

  }

  loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.iframes = data.iframes;
      this.instructions = data.iframes.instructions;
    });
  }
  generarIframe(): void {
    const id = this.obtenerIdDeURL(this.url);

    if (id) {
      // Si es un video de YouTube
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}?controls=0`);
      this.iframeCode = `<iframe src="https://www.youtube.com/embed/${id}?autoplay=1&controls=1&showinfo=0&modestbranding=1&rel=0"
      height="500" width="800" frameborder="0" allowfullscreen allow="autoplay;"></iframe>`;
    } else if (this.url) {
      // Si no es un video de YouTube, se genera un iframe genérico
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      this.iframeCode = `<iframe src="${this.url}" style="display: block; margin: 0 auto; width: 100%; height: 600px;" frameborder="0"></iframe>`;
    } else {
      this.iframeSrc = undefined;
      this.iframeCode = undefined;
      alert('Por favor, ingresa una URL válida.');
    }
  }

  obtenerIdDeURL(url: string): string | null {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\/.+\/))([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  }

  copyText() {
    navigator.clipboard.writeText(this.iframeCode).then(() => {
      alert('Texto copiado al portapapeles!');
    }).catch(err => {
      console.error('Error al copiar el texto: ', err);
    });
  }
}
